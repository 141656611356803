import { PriceUnitTypes } from '../constants';
import Utils from '../Utils';
import NumberUnit from './NumberUnit';
import VolumeUnit from './VolumeUnit';
import WeightUnit from './WeightUnit';
import BigNumber from 'bignumber.js';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

class UnitConverter {
  constructor() {
    this.weightUnit = new WeightUnit();
    this.VolumeUnit = new VolumeUnit();
    this.NumberUnit = new NumberUnit();
  }

  _getUnitInstance(priceUnitType) {
    let instance = null;
    switch (priceUnitType) {
      case PriceUnitTypes.Weight:
        instance = this.weightUnit;
        break;
      case PriceUnitTypes.Volume:
        instance = this.VolumeUnit;
        break;
      case PriceUnitTypes.Number:
        instance = this.NumberUnit;
        break;
      default:
        instance = null;
        break;
    }
    return instance;
  }

  /**
   *【单价转换】 后端返回值 --> 前端显示值
   * 分/吨  ->  元/吨    priceUnitType: 1  重量
   * 分/吨  ->  元/千克  priceUnitType: 1  重量
   * 分/方  ->  元/方    priceUnitType: 2  方
   * 分/件  ->  元/件    priceUnitType: 3  件
   * @param {*} val
   * @param {*} priceUnitType 后台返回的单位类型
   * @param {*} weightUnit
   * @returns
   */
  cent2priceUnit(val, priceUnitType, weightUnit) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.cent2priceUnit(val, weightUnit);
  }

  /**
   *【单价转换】 前端显示值 --> 后端返回值
   * 元/吨  ->  分/吨    priceUnitType: 1  重量
   * 元/吨  ->  分/千克  priceUnitType: 1  重量
   * 元/方  ->  分/方    priceUnitType: 2  方
   * 元/件  ->  分/件    priceUnitType: 3  件
   * @param {*} val
   * @param {*} priceUnitType 后台返回的单位类型
   * @param {*} weightUnit
   * @returns
   */
  priceUnit2cent(val, priceUnitType, weightUnit) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.priceUnit2cent(val, weightUnit);
  }

  /**
   * 立方米 转成 立方分米
   */
  volume2dm(val, priceUnitType = PriceUnitTypes.Weight) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.volume2dm(val);
  }
  /**
   * 立方分米 转成 立方米
   */
  dm2volume(val, priceUnitType = PriceUnitTypes.Weight) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.dm2volume(val);
  }

  /**
   * 吨/千克 转换成 克
   * @param {*} val
   * @param {*} weightUnit 重量单位, 不穿入默认取全局的单位配置信息 1:吨 2:千克
   * @returns
   */
  weight2gram(val, weightUnit, priceUnitType = PriceUnitTypes.Weight) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.weight2gram(val, weightUnit);
  }
  /**
   * 吨/千克 转换成 克
   * @param {*} val
   * @param {*} weightUnit 重量单位, 不穿入默认取全局的单位配置信息 1:吨 2:千克
   * @returns
   */
  gram2weight(val, weightUnit, priceUnitType = PriceUnitTypes.Weight) {
    const instance = this._getUnitInstance(priceUnitType);
    if (!instance || !Utils.isNumber(val)) return null;
    return instance.gram2weight(val, weightUnit);
  }

  val2number(val) {
    return Utils.toNumber(val);
  }

  cent2yuan(val) {
    if (!Utils.isNumber(val)) return null;
    return new BigNumber(val).div(100).toNumber();
  }
  yuan2cent(val) {
    if (!Utils.isNumber(val)) return null;
    return new BigNumber(val).times(100).toNumber();
  }
}

export default new UnitConverter();
