import { PricePlanTypes, SettlementMethodTypes } from '../constants';

export default class Base {
  constructor() {
    this.rules = null;
    this.options = null;
    this.values = null;

    this.labels = '';
    this.costs = null;
  }

  setRules(rules) {
    this.rules = rules;
    return this;
  }

  setOptions(options) {
    this.options = options;
    return this;
  }

  setValues(values) {
    this.values = values;
    return this;
  }

  setLabels(labels) {
    this.labels = labels;
    return this;
  }

  setCosts(costs) {
    this.costs = costs;
    return this;
  }

  build() {
    const { settlementMethod, pricePlanType } = this.rules;
    let resultMap = { labels: '', costs: null };
    if (pricePlanType === PricePlanTypes.FixedPrice) {
      resultMap.labels = this.getFixedLabel();
      resultMap.costs = this.getFixedCost();
    } else if (pricePlanType === PricePlanTypes.UnitPrice) {
      if (settlementMethod === SettlementMethodTypes.Loading) {
        resultMap.labels = this.getLoadingLabel();
        resultMap.costs = this.getLoadingCost();
      } else if (settlementMethod === SettlementMethodTypes.Unloading) {
        resultMap.labels = this.getUnloadingLabel();
        resultMap.costs = this.getUnloadingCost();
      } else if (settlementMethod === SettlementMethodTypes.Minimum) {
        resultMap.labels = this.getMinimumLabel();
        resultMap.costs = this.getMinimumCost();
      }
    }
    return resultMap;
  }
}
