// const pricingRules = {
// 	allowLossNumber: null,
// 	allowLossNumberUnit: null,
// 	calculatePrice: null,
// 	calculationFormula: null,
// 	lossPrice: null,
// 	lossRule: null,
// 	numberLimit: null,
// 	pricePlanType: null,
// 	priceUnitType: null,
// 	settlementMethod: null,
// 	smallChangeRule: null,
// 	totalTransportPrice: null,
// 	transportPrice: null,
// 	volumeLimit: null,
// 	weightLimit: null
// }
// 所有命名尽量和后端保持一致

export const OriginalOptions = {
  weightUnit: 1, // 1: 吨  2: kg
  loadingWeight: null,
  loadingVolume: null,
  loadingNumber: null,
  unloadingWeight: null,
  unloadingVolume: null,
  unloadingNumber: null
};

export const OriginalRules = {
  allowLossNumber: null,
  allowLossNumberUnit: null,
  calculatePrice: null,
  calculationFormula: null,
  lossPrice: null,
  lossRule: null,
  numberLimit: null,
  pricePlanType: null,
  priceUnitType: null,
  settlementMethod: null,
  smallChangeRule: null,
  totalTransportPrice: null,
  transportPrice: null,
  volumeLimit: null,
  weightLimit: null,
  otherFine: null,
  otherFineUnit: null
};

// 计价规则类型
export const PricePlanTypes = {
  UnitPrice: 0, // 按单价（计价规则算）
  FixedPrice: 1 // 按整车（固定价格，不需要计算）
};

// 计价维度 （按装货，按卸货，按装卸货最小值）
export const SettlementMethodTypes = {
  Loading: 0, // 按装货数量
  Unloading: 1, // 按卸货数量
  Minimum: 2 // 装卸货的最小值
};

// 扣除亏损规则
export const LossRuleTypes = {
  NonDeduct: 0, // 不扣除亏损
  Deduct: 1 // 扣除亏损
};

// 单价单位
export const PriceUnitTypes = {
  Weight: 1, // 元/吨 元/kg
  Volume: 2, // 元方
  Number: 3 // 元/件
};

// 抹零规则
export const SmallChangeRuleTypes = {
  Dime: 0, // 角分抹零
  Ten: 1, // 10元以下抹零
  Zero: 2 // 不抹零
};

// 允许亏损单位类型
export const AllowLossNumberUnitTypes = {
  Unit: 0, // 吨 千克 方 件
  Permillage: 1 // 千分比
};

// 重量单位
export const WeightUnitTypes = {
  Ton: 1,
  Kg: 2
};
export const WeightUnitLabels = {
  [WeightUnitTypes.Ton]: '吨',
  [WeightUnitTypes.Kg]: '千克'
};

// 其他扣款
export const TheOtherFineUnitTypes = {
  Yuan: 1,
  Percent: 2
};

// 抹零规则
export const SmallChangeTypes = {
  // 0：角分抹零，1：10元以下抹零，2：不抹零
  Dime: 0,
  Ten: 1,
  Zero: 2
};

export const SmallChangeLabels = {
  // 0：角分抹零，1：10元以下抹零，2：不抹零
  [SmallChangeTypes.Dime]: '角分抹零',
  [SmallChangeTypes.Ten]: '10元以下抹零',
  [SmallChangeTypes.Zero]: '不抹零'
};

// ====支付计划=====
// 油卡
export const OilCardUnitTypes = {
  Yuan: 1,
  Percent: 2,
  Price: 3
};

export const OilCardRoundingTypes = {
  Ten: 2, // 向上10元取整
  Fifty: 3, // 向上50元取整
  Hundred: 4, // 向上100元取整
  Round: 1 // 四舍五入
};

// 运单状态
export const WaybillStatusMap = {
  ToBeAssigned: 20, // 待分配
  Assigned: 30, // 已分配
  Loaded: 40, // 已装货
  Unloaded: 50, // 已卸货
  Signed: 60 // 已签收
};

// 字段和后端保持一致
export const PaymentTypes = {
  WholeCode: 10100, // 整单付款

  OilCardCode: 20100, // 油卡付款
  PreCode: 20200, // 预付
  ArriveCode: 20300, // 到付
  ReceiptCode: 20400, // 回单付
  InfoCode: 20500, //信息费

  ServiceCode: 90100 // 服务费
};
