import Base from './Base';
import { SmallChangeLabels, SmallChangeRuleTypes } from '../constants';
import BigNumber from 'bignumber.js';
import Utils from '../Utils';
export default class SmallChangeRule extends Base {
  _getLabels() {
    const { smallChangeRule } = this.rules;
    let label = SmallChangeLabels[smallChangeRule] || '';
    if (smallChangeRule === SmallChangeRuleTypes.Dime || smallChangeRule === SmallChangeRuleTypes.Ten) {
      label = ` - ${label}`;
    } else {
      label = '';
    }
    return `${this.labels}${label}`;
  }

  _getCosts() {
    if (!Utils.isNumber(this.costs)) {
      return this.costs;
    }
    if (this.costs < 0) {
      return 0;
    }
    const { smallChangeRule } = this.rules;
    if (smallChangeRule === SmallChangeRuleTypes.Dime) {
      this.costs = new BigNumber(this.costs).integerValue(BigNumber.ROUND_DOWN).toNumber();
    } else if (smallChangeRule === SmallChangeRuleTypes.Ten) {
      this.costs = new BigNumber(this.costs)
        .div(10)
        .integerValue(BigNumber.ROUND_DOWN)
        .times(10)
        .toNumber();
    }
    return parseFloat(
      new BigNumber(this.costs)
        .times(100)
        .integerValue(BigNumber.ROUND_HALF_UP)
        .toFixed(2)
    );
  }

  getFixedLabel() {
    return this.labels;
  }

  getFixedCost() {
    if (!Utils.isNumber(this.costs)) {
      return this.costs;
    }
    if (this.costs < 0) {
      return 0;
    }
    return parseFloat(
      new BigNumber(this.costs)
        .times(100)
        .integerValue()
        .toFixed(2)
    );
  }

  getLoadingLabel() {
    return this._getLabels();
  }

  getUnloadingLabel() {
    return this._getLabels();
  }

  getMinimumLabel() {
    return this._getLabels();
  }

  getLoadingCost() {
    return this._getCosts();
  }

  getUnloadingCost() {
    return this._getCosts();
  }

  getMinimumCost() {
    return this._getCosts();
  }
}
