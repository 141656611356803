/**
 * 在这里 把计价规则单位转换下
 */
import CostsRule from './CostsRule';
import LossRule from './LossRule';
import OtherFineRule from './OtherFineRule';
import SmallChangeRule from './SmallChangeRule';
import UnitConverter from '../UnitConverter';
import { PriceUnitTypes, WeightUnitLabels, OriginalOptions, OriginalRules } from '../constants';
import Utils from '../Utils';

export default class PricingRules {
  constructor() {
    this.sequence = [];
    this.rules = null;
    this.options = null;
    this.costs = null;
    this.isPaidCosts = false;
    this._init();
  }

  _init() {
    const costsRule = new CostsRule();
    const lossRule = new LossRule();
    const otherFineRule = new OtherFineRule();
    const smallChangeRule = new SmallChangeRule();
    this._addRule(costsRule)
      ._addRule(lossRule)
      ._addRule(otherFineRule)
      ._addRule(smallChangeRule);
  }

  // 添加组件到sequence
  _addRule(rule) {
    this.sequence.push(rule);
    return this;
  }

  setRules(rules) {
    this.rules = { ...OriginalRules, ...rules };
    return this;
  }

  setOptions(options) {
    // 设置默认值
    this.options = { ...OriginalOptions, ...options };
    return this;
  }

  // 有可能没有计价规则，直接返回设置的运费
  setCosts(costs) {
    this.costs = costs ?? null;
    return this;
  }

  // 设置各个类型支付状态，如果支付完服务费或完成就不要计算
  setStatus(status) {
    this.isPaidCosts = Utils.isPaidCosts(status);
    return this;
  }

  build() {
    const resultMap = { labels: '', costs: null };
    if (Utils.isEmptyPricingRules(this.rules)) {
      resultMap.costs = this.costs ?? 0;
      return resultMap;
    }
    const values = this._getValuesByPriceUnit();
    let len = this.sequence.length;
    for (let i = 0; i < len; i++) {
      const rule = this.sequence[i];
      const { labels, costs } = rule
        .setRules(this.rules)
        .setOptions(this.options)
        .setValues(values)
        .setLabels(resultMap.labels)
        .setCosts(resultMap.costs)
        .build();
      resultMap.labels = labels;
      resultMap.costs = costs;
    }
    if (resultMap.labels) {
      resultMap.labels = `运费 = ${resultMap.labels}`;
    }
    // 支付过服务费，不能重新计算运费
    if (this.isPaidCosts) {
      resultMap.costs = this.costs;
    }
    if (resultMap.costs <= 0) {
      resultMap.costs = 0;
    }
    return resultMap;
  }

  /**
   * 根据价格单位提取所需要的信息
   * 1 重量
   *   |-> 吨
   *   |-> 千克
   * 2 方
   * 3 件
   */
  _getValuesByPriceUnit() {
    const { priceUnitType } = this.rules;
    let values = {
      priceUnitLabel: '', // 元/吨 ...
      typeLabel: '', // 重量 件数 体积
      typeUnitLabel: '', // 吨 千克 方 件
      loadingNumber: null, // 装货数量
      unloadingNumber: null // 卸货数量
    };
    switch (priceUnitType) {
      case PriceUnitTypes.Weight:
        this._getWeightValues(values);
        break;
      case PriceUnitTypes.Volume:
        this._getVolumeValues(values);
        break;
      case PriceUnitTypes.Number:
        this._getNumberValues(values);
        break;
    }
    return values;
  }

  _getWeightValues(values) {
    const { priceUnitType } = this.rules;
    const { weightUnit, loadingWeight, unloadingWeight } = this.options;
    values.priceUnitLabel = WeightUnitLabels[weightUnit] ? `元/${WeightUnitLabels[weightUnit]}` : '';
    values.typeLabel = '重量';
    values.typeUnitLabel = WeightUnitLabels[weightUnit] || '';
    values.loadingNumber = UnitConverter.gram2weight(loadingWeight, weightUnit, priceUnitType);
    values.unloadingNumber = UnitConverter.gram2weight(unloadingWeight, weightUnit, priceUnitType);
    return values;
  }

  _getVolumeValues(values) {
    const { loadingVolume, unloadingVolume } = this.options;
    values.priceUnitLabel = '元/方';
    values.typeLabel = '体积';
    values.typeUnitLabel = '方';
    values.loadingNumber = UnitConverter.dm2volume(loadingVolume);
    values.unloadingNumber = UnitConverter.dm2volume(unloadingVolume);
    return values;
  }

  _getNumberValues(values) {
    const { loadingNumber, unloadingNumber } = this.options;
    values.priceUnitLabel = '元/件';
    values.typeLabel = '件数';
    values.typeUnitLabel = '件';
    values.loadingNumber = UnitConverter.val2number(loadingNumber);
    values.unloadingNumber = UnitConverter.val2number(unloadingNumber);
    return values;
  }
}
