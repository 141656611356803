import BigNumber from 'bignumber.js';
import Utils from '../Utils';
import { OilCardRoundingTypes, OilCardUnitTypes, PaymentTypes } from '../constants';
import { SettlementMethodMap } from '@/views/driver/waybill/constants';

// 为了保持和form表单一致
export const PaymentTypeNames = {
  InformationPayment: 'informationPayment',
  OilCardPayment: 'oilCardPayment',
  PrePayment: 'prePayment',
  ArrivePayment: 'arrivePayment',
  ReceiptPayment: 'receiptPayment'
};

// 油卡百分比 是*100的数字

// const OilCardRules = {
//   oilRatioType: 1, // 元还是百分比
//   oilRatioNumber: 2000,
//   oilRatioPercentCalcRule: 1 // 选择百分比 四舍五入的规则
// };

// 支付计划，支持最小值，支持各种类型规则设置
// 支持输入支付计划，运费联动
export default class PaymentPlan {
  constructor() {
    this.resultMap = {
      receiptPayment: null,
      arrivePayment: null,
      prePayment: null,
      oilCardPayment: null,
      informationPayment: null,
      costs: null
    };

    // 承运人运费
    this.costs = null;
    this.originCosts = null;

    // 默认值
    this.values = {
      informationPayment: null,
      oilCardPayment: null,
      prePayment: null,
      arrivePayment: null,
      receiptPayment: null
    };

    // 支付状态
    this.status = {
      wholeStatus: false,
      serviceStatus: false,

      // 支付计划
      oilCardStatus: false,
      preStatus: false,
      arriveStatus: false,
      receiptStatus: false,
      infoStatus: false
    };

    // 各种配置
    // eg：油卡付 固定元 还是百分比
    this.rules = {
      oilCardRule: null,
      preRule: null,
      arriveRule: null,
      receiptRule: null
    };

    // 记录当前活动的支付类型
    this.activeTypeName = null;

    this.goodsInfo = null;
  }
  // 有运费才能算支付计划
  setCosts(costs) {
    this.costs = Number(costs) ?? 0;
    this.originCosts = costs;
    return this;
  }

  // 设置各个类型的值 用||是应为可能传入的是''
  setValues(values = {}) {
    this.values.informationPayment = values.informationPayment || 0;
    this.values.oilCardPayment = values.oilCardPayment || 0;
    this.values.prePayment = values.prePayment || 0;
    this.values.arrivePayment = values.arrivePayment || 0;
    this.values.receiptPayment = values.receiptPayment || 0;
    return this;
  }

  // 设置各个类型的支付状态
  setStatus(status = {}) {
    this.status.wholeStatus = status[PaymentTypes.WholeCode] ?? false;
    this.status.oilCardStatus = status[PaymentTypes.OilCardCode] ?? false;
    this.status.preStatus = status[PaymentTypes.PreCode] ?? false;
    this.status.arriveStatus = status[PaymentTypes.ArriveCode] ?? false;
    this.status.receiptStatus = status[PaymentTypes.ReceiptCode] ?? false;
    this.status.serviceStatus = status[PaymentTypes.ServiceCode] ?? false;
    this.status.infoStatus = status[PaymentTypes.InfoCode] ?? false;
    return this;
  }

  // 各种支付类型 算值的方式
  setRules(rules = {}) {
    this.rules.oilCardRule = rules.oilCardRule || null;
    this.rules.preRule = rules.preRule || null;
    this.rules.arriveRule = rules.arriveRule || null;
    this.rules.receiptRule = rules.receiptRule || null;
    this.rules.infoRule = rules.infoRule || null;
    return this;
  }

  // 输入的时候用
  setActiveName(name) {
    this.activeTypeName = name ?? null;
    return this;
  }

  setGoodsInfo(data) {
    this.goodsInfo = data;
    return this;
  }

  build() {
    if (Utils.isNil(this.originCosts)) {
      return this.resultMap;
    }

    if (!this._needRebuildPayment() && !this.goodsInfo?.calcInfo) {
      return { ...this.values, costs: this.costs };
    }

    const list = this._composePriorityList();
    const { remainingValue, totalValue, fixedTotalValue } = this._getRemainingValueAndTotalValue(list);
    let diff = new BigNumber(this.costs).minus(totalValue).toNumber();
    let ratioValue = 0;
    if (list[3].ratioValue && !this.status.oilCardStatus) {
      diff = new BigNumber(diff)
        .plus(list[3].value)
        .minus(list[3].ratioValue)
        .toNumber();
      list[3].value = list[3].ratioValue;
      ratioValue = list[3].ratioValue;
    }
    if (list[2].ratioValue && !this.status.preStatus) {
      diff = new BigNumber(diff)
        .plus(list[2].value)
        .minus(list[2].ratioValue)
        .toNumber();
      list[2].value = list[2].ratioValue;
      ratioValue = list[2].ratioValue;
    }
    if (list[4].ratioValue && this.rules.infoRule.visiable && !this.status.infoStatus) {
      const leaveVal = new BigNumber(this.costs)
        .minus(fixedTotalValue)
        .minus(ratioValue)
        .toNumber();
      let infoRatio = 0;
      if (leaveVal > list[4].ratioValue) {
        infoRatio = list[4].ratioValue;
      } else if (leaveVal < list[4].ratioValue && leaveVal > 0) {
        infoRatio = leaveVal;
      }
      diff = new BigNumber(diff)
        .plus(list[4].value)
        .minus(infoRatio)
        .toNumber();
      list[4].value = infoRatio;
    }

    let curItem = null;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.fixed) {
        continue;
      }
      if (item.active) {
        curItem = item;
        continue;
      }
      if (item.value + diff > item.minValue) {
        diff = new BigNumber(item.value).plus(diff).toNumber();
      } else {
        diff = new BigNumber(item.value)
          .minus(item.minValue)
          .plus(diff)
          .toNumber();
      }
      if (diff >= 0) {
        item.value = diff;
        break;
      } else {
        item.value = item.minValue;
      }
    }
    if (curItem && curItem.value > remainingValue) {
      curItem.value = remainingValue;
    }
    return {
      receiptPayment: list[0].value,
      arrivePayment: list[1].value,
      prePayment: list[2].value,
      oilCardPayment: list[3].value,
      informationPayment: list[4].value,
      costs: new BigNumber(list[0].value)
        .plus(list[1].value)
        .plus(list[2].value)
        .plus(list[3].value)
        .plus(list[4].value)
        .toNumber()
    };
  }

  // [回单，到付，预付，油卡付]
  // 支持固定最小值留作扩展
  _composePriorityList() {
    const { receiptPayment, arrivePayment, prePayment, oilCardPayment, informationPayment } = this.values;
    const { receiptStatus, arriveStatus, preStatus, oilCardStatus, infoStatus } = this.status;
    let receiptMinValue = receiptStatus ? receiptPayment : 0;
    let arriveMinValue = arriveStatus ? arrivePayment : 0;
    let preMinValue = preStatus ? prePayment : 0;
    let infoMinValue = infoStatus ? informationPayment : 0;

    // 有activeTypeName说明再编辑支付计划，所有minValue=0
    // 否则说明是运费变动了，需要根据规则初始化计算值
    let oilCardMinValue = oilCardStatus ? oilCardPayment : 0;

    return [
      {
        name: PaymentTypeNames.ReceiptPayment,
        value: Math.max(receiptPayment, receiptMinValue),
        minValue: receiptMinValue,
        ratioValue: 0,
        fixed: this.status.receiptStatus,
        active: this.activeTypeName === PaymentTypeNames.ReceiptPayment
      },
      {
        name: PaymentTypeNames.ArrivePayment,
        value: Math.max(arrivePayment, arriveMinValue),
        minValue: arriveMinValue,
        ratioValue: 0,
        fixed: this.status.arriveStatus,
        active: this.activeTypeName === PaymentTypeNames.ArrivePayment
      },
      {
        name: PaymentTypeNames.PrePayment,
        value: Math.max(prePayment, preMinValue),
        minValue: preMinValue,
        ratioValue: this._getPrePaymentMinValueByRules(),
        fixed: this.status.preStatus,
        active: this.activeTypeName === PaymentTypeNames.PrePayment
      },
      {
        name: PaymentTypeNames.OilCardPayment,
        value: Math.max(oilCardPayment, oilCardMinValue),
        minValue: oilCardMinValue,
        ratioValue: this._getOilCardMinValueByRules(),
        fixed: this.status.oilCardStatus,
        active: this.activeTypeName === PaymentTypeNames.OilCardPayment
      },
      {
        name: PaymentTypeNames.InformationPayment,
        value: Math.max(informationPayment, infoMinValue),
        minValue: infoMinValue,
        ratioValue: this._getInformationMinValueByRules(),
        fixed: this.status.infoStatus,
        active: this.activeTypeName === PaymentTypeNames.InformationPayment
      }
    ];
  }

  _needRebuildPayment() {
    const { oilCardPayment, prePayment, arrivePayment, receiptPayment, informationPayment } = this.values;
    const total = new BigNumber(oilCardPayment)
      .plus(prePayment)
      .plus(arrivePayment)
      .plus(receiptPayment)
      .plus(informationPayment)
      .toNumber();
    if (total !== this.costs) {
      return true;
    }
    return false;
  }

  _getRemainingValueAndTotalValue(list) {
    let fixedTotalValue = 0;
    let remainingValue = 0;
    let totalValue = 0;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.fixed) {
        fixedTotalValue = new BigNumber(item.value).plus(fixedTotalValue).toNumber();
      }
      totalValue = new BigNumber(item.value).plus(totalValue).toNumber();
    }
    remainingValue = new BigNumber(this.costs).minus(fixedTotalValue).toNumber();
    return {
      remainingValue,
      fixedTotalValue,
      totalValue
    };
  }

  // number 10 50 100
  // 10，50，100向上取整
  _getRoundingValue(value, number) {
    // console.log('value: ' + value + ' number: ' + number);

    let ratio = 0;
    let dp = null;
    if (number === 10) {
      ratio = 1000;
      dp = 2;
    } else if (number === 50 || number === 100) {
      ratio = 10000;
      dp = 2;
    } else {
      dp = null;
    }
    if (value <= number * 100) {
      return new BigNumber(value).toNumber();
    }
    return new BigNumber(value)
      .div(ratio)
      .dp(0, dp)
      .times(ratio)
      .toNumber();
  }

  _getOilCardMinValueByRules() {
    if (!this.rules.oilCardRule) return this.values.oilCardPayment;
    const { oilRatioNumber, oilRatioType, oilRatioPercentCalcRule } = this.rules.oilCardRule;
    // 固定的元
    if (oilRatioType === OilCardUnitTypes.Yuan) {
      return oilRatioNumber || 0;
    }
    // 固定的运费比例 分
    let oilCardMinValue = 0;
    if (oilRatioType === OilCardUnitTypes.Percent) {
      const oilRatio = new BigNumber(oilRatioNumber || 0).div(10000);
      const value = new BigNumber(this.costs).times(oilRatio).toNumber();
      if (oilRatioPercentCalcRule === OilCardRoundingTypes.Ten) {
        oilCardMinValue = this._getRoundingValue(value, 10);
      } else if (oilRatioPercentCalcRule === OilCardRoundingTypes.Fifty) {
        oilCardMinValue = this._getRoundingValue(value, 50);
      } else if (oilRatioPercentCalcRule === OilCardRoundingTypes.Hundred) {
        oilCardMinValue = this._getRoundingValue(value, 100);
      } else if (oilRatioPercentCalcRule === OilCardRoundingTypes.Round) {
        oilCardMinValue = new BigNumber(value).dp(0).toNumber();
      }
    }
    return oilCardMinValue;
  }

  _getPrePaymentMinValueByRules() {
    if (!this.rules.preRule) return this.values.prePayment;
    const { preRatioNumber, preRatioType, preRatioPercentCalcRule } = this.rules.preRule;
    // 固定的元
    if (preRatioType === OilCardUnitTypes.Yuan) {
      return preRatioNumber || 0;
    }
    // 固定的运费比例 分
    let preMinValue = 0;
    if (preRatioType === OilCardUnitTypes.Percent) {
      const preRatio = new BigNumber(preRatioNumber || 0).div(10000);
      const value = new BigNumber(this.costs).times(preRatio).toNumber();
      if (preRatioPercentCalcRule === OilCardRoundingTypes.Ten) {
        preMinValue = this._getRoundingValue(value, 10);
      } else if (preRatioPercentCalcRule === OilCardRoundingTypes.Fifty) {
        preMinValue = this._getRoundingValue(value, 50);
      } else if (preRatioPercentCalcRule === OilCardRoundingTypes.Hundred) {
        preMinValue = this._getRoundingValue(value, 100);
      } else if (preRatioPercentCalcRule === OilCardRoundingTypes.Round) {
        preMinValue = new BigNumber(value).dp(0).toNumber();
      }
    }
    return preMinValue;
  }

  _getInformationMinValueByRules() {
    if (!this.rules.infoRule) return this.values.informationPayment;
    const { informationRatioNumber, informationRatioType, informationSettlementMethod } = this.rules.infoRule;
    // 固定的元
    if (informationRatioType === OilCardUnitTypes.Yuan) {
      return informationRatioNumber || 0;
    }
    // 固定的运费比例 分
    let infoMinValue = 0;
    if (informationRatioType === OilCardUnitTypes.Percent) {
      const infoRatio = new BigNumber(informationRatioNumber || 0).div(10000);
      infoMinValue = new BigNumber(this.costs)
        .times(infoRatio)
        .dp(0)
        .toNumber();
    }

    if (informationRatioType === OilCardUnitTypes.Price && this.goodsInfo) {
      let num = 0;
      if (informationSettlementMethod === SettlementMethodMap.Loading) {
        num = this.goodsInfo.load;
      } else if (informationSettlementMethod === SettlementMethodMap.Unloading) {
        num = this.goodsInfo.unload || this.goodsInfo.load;
      } else if (informationSettlementMethod === SettlementMethodMap.Minimum) {
        num = this.goodsInfo.unload ? Math.min(this.goodsInfo.load, this.goodsInfo.unload) : this.goodsInfo.load;
      }
      this.goodsInfo = null;
      infoMinValue = new BigNumber(informationRatioNumber)
        .multipliedBy(num)
        .div(1000000)
        .integerValue(BigNumber.ROUND_HALF_CEIL)
        .toNumber();
    }

    return infoMinValue;
  }
}

// const paymentPlan = new PaymentPlan();
// paymentPlan
//   .setCosts()
//   .setValues()
//   .setStatus()
//   .setRules()
//   .build();
