import BigNumber from 'bignumber.js';
import { WeightUnitTypes } from '../constants';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export default class Base {
  volume2dm(val) {
    return new BigNumber(val).times(1000).toNumber();
  }
  dm2volume(val) {
    return new BigNumber(val).div(1000).toNumber();
  }

  cent2priceUnit(val) {
    // 分/件 -> 元/件
    return new BigNumber(val).div(100).toNumber();
  }
  priceUnit2cent(val) {
    // 元/件 -> 分/件
    return new BigNumber(val).times(100).toNumber();
  }

  weight2gram(val, weightUnit) {
    const bigNumber = new BigNumber(val);
    if (weightUnit === WeightUnitTypes.Ton) {
      return bigNumber.times(1000000).toNumber();
    }
    if (weightUnit === WeightUnitTypes.Kg) {
      return bigNumber.times(1000).toNumber();
    }
    return null;
  }
  gram2weight(val, weightUnit) {
    const bigNumber = new BigNumber(val);
    if (weightUnit === WeightUnitTypes.Ton) {
      return bigNumber.div(1000000).toNumber();
    }
    if (weightUnit === WeightUnitTypes.Kg) {
      return bigNumber.div(1000).toNumber();
    }
    return null;
  }
}
