export const RateLevelLabel = {
  1: '极差',
  2: '较差',
  3: '一般',
  4: '较好',
  5: '非常好'
};

// 已接单30 已装货40 已卸货50 已签收60
export const WaybillStatusMap = {
  Received: 30,
  Loaded: 40,
  Unloaded: 50,
  Signed: 60
};

export const WaybillStatusDescMap = {
  [WaybillStatusMap.Received]: { color: 'text-warning', label: '已接单' },
  [WaybillStatusMap.Loaded]: { color: 'text-success', label: '已装货' },
  [WaybillStatusMap.Unloaded]: { color: 'text-muted', label: '已卸货' },
  [WaybillStatusMap.Signed]: { color: 'text-muted', label: '已签收' }
};

// 计价规则类型
export const PricePlanTypeMap = {
  UnitPrice: 0, // 单价
  FixedPrice: 1 // 整车
};

// 重量单位
export const WeightUnitMap = {
  Ton: 1,
  Kg: 2
};

export const WeightUnitDescMap = {
  [WeightUnitMap.Ton]: '吨',
  [WeightUnitMap.Kg]: '千克'
};

// 单价单位
export const UnitPriceTypeMap = {
  Weight: 1, // 元/吨 元/kg
  Volume: 2, // 元方
  Number: 3 // 元/件
};

// 支付计划
export const PayTypeMap = {
  WholePayment: 10100,
  OilCardPayment: 20100,
  PrePayment: 20200,
  ArrivePayment: 20300,
  ReceiptPayment: 20400,
  InformationPayment: 20500,
  ServicePayment: 90100
};

export const PayTypeDescMap = {
  [PayTypeMap.WholePayment]: 'wholePayment',
  [PayTypeMap.OilCardPayment]: 'oilCardPayment',
  [PayTypeMap.PrePayment]: 'prePayment',
  [PayTypeMap.ArrivePayment]: 'arrivePayment',
  [PayTypeMap.ReceiptPayment]: 'receiptPayment',
  [PayTypeMap.InformationPayment]: 'informationPayment',
  [PayTypeMap.ServicePayment]: 'servicePayment'
};

// 计价维度
export const SettlementMethodMap = {
  Loading: 0, // 按装货数量
  Unloading: 1, // 按卸货数量
  Minimum: 2 // 装卸货的最小值
};

// 抹零规则
export const SmallChangeRuleMap = {
  // 0：角分抹零，1：10元以下抹零，2：不抹零
  Dime: 0,
  Ten: 1,
  Zero: 2
};

// 扣除亏损规则
export const LossRuleMap = {
  NoDeduct: 0, // 不扣除亏损
  Deduct: 1 // 扣除亏损
};

export const LabelDescMap = {
  loading: '装货',
  unloading: '卸货'
};

export const LabelMap = {
  Loading: 'loading',
  Unloading: 'unloading'
};
