import BigNumber from 'bignumber.js';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });
import { PaymentTypes } from './constants';

export default class Utils {
  static isNil(val) {
    return val === undefined || val === null;
  }

  // 是数字，允许为0
  static isNumber(val) {
    return !(Utils.isNil(val) || isNaN(val) || val === '');
  }

  static isValidNumber(val) {
    if (Utils.isNil(val) || isNaN(val) || Number(val) < 0) return false;
    return true;
  }

  static isInvalidNumber(val) {
    return !Utils.isValidNumber(val);
  }

  static keepDecimal(val, places) {
    if (!Utils.isNumber) return null;
    return new BigNumber(val).toFixed(places);
  }

  static toNumber(val) {
    if (!Utils.isNumber) return null;
    return new BigNumber(val).toNumber();
  }

  // 根据状态筛选出记录
  static filterRecordList(recordList, status, transportBillStatus) {
    let matchedRecord = null;
    if (!recordList) recordList = [];
    for (let i = recordList.length - 1; i >= 0; i--) {
      const record = recordList[i];
      if (record.statusOperate === status && record.statusOperationDirection === 0 && transportBillStatus >= status) {
        matchedRecord = record;
        break;
      }
    }
    return matchedRecord;
  }

  // 判断是空的计价规则, 简单判断
  static isEmptyPricingRules(rules) {
    if (!rules) return true;
    const { pricePlanType } = rules;
    if (Utils.isNil(pricePlanType)) {
      return true;
    }
    return false;
  }

  // 判断钱已支付完成
  static isPaidCosts(status) {
    const wholeStatus = status[PaymentTypes.WholeCode] ?? false;
    const serviceStatus = status[PaymentTypes.ServiceCode] ?? false;
    // 支付过 服务费 或者 整单付过后 会锁定整个支付计划
    if (serviceStatus || wholeStatus) return true;
    const oilCardStatus = status[PaymentTypes.OilCardCode] ?? false;
    const preStatus = status[PaymentTypes.PreCode] ?? false;
    const arriveStatus = status[PaymentTypes.ArriveCode] ?? false;
    const receiptStatus = status[PaymentTypes.ReceiptCode] ?? false;
    if (oilCardStatus && preStatus && arriveStatus && receiptStatus) {
      return true;
    }
    return false;
  }
}
