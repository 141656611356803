import Base from './Base';
import Utils from '../Utils';
import BigNumber from 'bignumber.js';
import UnitConverter from '../UnitConverter';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });
/**
 * 计价规则的第一部分
 * 包含：【运输单价，计价维度】
 * 单价是 必填项
 */
export default class CostsRule extends Base {
  _getLabels() {
    const { transportPrice } = this.rules;
    const { typeLabel, priceUnitLabel } = this.values;
    // 转换成 元/吨 元/件
    let priceLabel = Utils.isInvalidNumber(transportPrice) ? '' : Utils.keepDecimal(this._getTransportPrice(), 2);
    return { priceLabel, typeLabel, priceUnitLabel };
  }

  _getTransportPrice() {
    const { transportPrice, priceUnitType } = this.rules;
    const { weightUnit } = this.options;
    // 运输单价必须要>0
    if (Utils.isInvalidNumber(transportPrice)) {
      return null;
    }
    return UnitConverter.cent2priceUnit(transportPrice, priceUnitType, weightUnit);
  }

  getFixedLabel() {
    const cost = this.getFixedCost();
    if (!cost) {
      return `0.00元`;
    }
    return `${cost}元`;
  }

  getLoadingLabel() {
    const { typeLabel, priceLabel, priceUnitLabel } = this._getLabels();
    return `装货${typeLabel} * ${priceLabel}${priceUnitLabel}`;
  }

  getUnloadingLabel() {
    const { typeLabel, priceLabel, priceUnitLabel } = this._getLabels();
    return `卸货${typeLabel} * ${priceLabel}${priceUnitLabel}`;
  }

  getMinimumLabel() {
    const { typeLabel, priceLabel, priceUnitLabel } = this._getLabels();
    return `min【装货${typeLabel}，卸货${typeLabel}】 * ${priceLabel}${priceUnitLabel}`;
  }

  // 装货重量 * 运输单价
  getLoadingCost() {
    const { loadingNumber } = this.values;
    const transportPrice = this._getTransportPrice();
    if (Utils.isInvalidNumber(transportPrice) || Utils.isInvalidNumber(loadingNumber)) {
      return null;
    }
    return new BigNumber(loadingNumber).times(transportPrice).toNumber();
  }

  // 卸货重量 * 运输单价
  getUnloadingCost() {
    const { unloadingNumber } = this.values;
    const transportPrice = this._getTransportPrice();
    if (Utils.isInvalidNumber(transportPrice) || Utils.isInvalidNumber(unloadingNumber)) {
      return null;
    }
    return new BigNumber(unloadingNumber).times(transportPrice).toNumber();
  }

  // min【装货重量，卸货重量】* 运输单价
  getMinimumCost() {
    const { loadingNumber, unloadingNumber } = this.values;
    const transportPrice = this._getTransportPrice();
    if (
      Utils.isInvalidNumber(loadingNumber) ||
      Utils.isInvalidNumber(unloadingNumber) ||
      Utils.isInvalidNumber(transportPrice)
    ) {
      return null;
    }
    return BigNumber.minimum(loadingNumber, unloadingNumber)
      .times(transportPrice)
      .toNumber();
  }

  getFixedCost() {
    const { totalTransportPrice } = this.rules;
    return UnitConverter.cent2yuan(totalTransportPrice);
  }
}
