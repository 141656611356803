import BigNumber from 'bignumber.js';
import { WeightUnitTypes } from '../constants';
import Base from './Base';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export default class WeightUnit extends Base {
  cent2priceUnit(val, weightUnit) {
    const bigNumber = new BigNumber(val);
    // 分/吨 -> 元/吨
    if (weightUnit === WeightUnitTypes.Ton) {
      return bigNumber.div(100).toNumber();
    }
    // 分/吨 -> 元/千克
    if (weightUnit === WeightUnitTypes.Kg) {
      return bigNumber.div(100000).toNumber();
    }
    return null;
  }
  priceUnit2cent(val, weightUnit) {
    const bigNumber = new BigNumber(val);
    // 元/吨 -> 分/吨
    if (weightUnit === WeightUnitTypes.Ton) {
      return bigNumber.times(100).toNumber();
    }
    // 元/千克 -> 分/吨
    if (weightUnit === WeightUnitTypes.Kg) {
      return bigNumber.times(100000).toNumber();
    }
    return null;
  }

  // weight2gram(val, weightUnit) {
  //   const bigNumber = new BigNumber(val);
  //   if (weightUnit === WeightUnitTypes.Ton) {
  //     return bigNumber.times(1000000).toNumber();
  //   }
  //   if (weightUnit === WeightUnitTypes.Kg) {
  //     return bigNumber.times(1000).toNumber();
  //   }
  //   return null;
  // }
  // gram2weight(val, weightUnit) {
  //   const bigNumber = new BigNumber(val);
  //   if (weightUnit === WeightUnitTypes.Ton) {
  //     return bigNumber.div(1000000).toNumber();
  //   }
  //   if (weightUnit === WeightUnitTypes.Kg) {
  //     return bigNumber.div(1000).toNumber();
  //   }
  //   return null;
  // }
}
