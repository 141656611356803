/**
 * 费用计算
 * 承运人运费 | 托运方运费 --> 计算逻辑一样
 * 总运费
 * 服务费
 *
 * 服务费
 *  1.
 */
import BigNumber from 'bignumber.js';
import Utils from '../Utils';
// import Utils from '../Utils'

// 服务费率配置方式
const ServiceCostsTaxTypes = {
  TaxInclusive: 1, // 含税 (默认)
  TaxExclusive: 2 // 不含税
};

// 服务费舍去模式
const ServiceCostsRoundingTypes = {
  10: BigNumber.ROUND_CEIL, // 向上取整 （默认）
  20: BigNumber.ROUND_HALF_CEIL, // 四舍五入
  30: BigNumber.ROUND_FLOOR // 向下取整
};

// 打个补丁吧，根据【carrier_cost_exceed_system】1: 不允许 2:允许
// 能否超出运费最大值
// function allowCarrierCostExceed() {
//   return +userStore.config.carrier_cost_exceed_system === 2;
// }

// 输入的值能否超出计价规则算出的运费
const CostsExcessTypes = {
  NotAllowExcess: 1, // 不允许超出
  AllowExcess: 2 // 允许超出 (默认)
};

const ActiveNames = {
  CarrierTransportCost: 'carrierTransportCost',
  ShipperTransportCost: 'shipperTransportCost',
  Cost: 'cost',

  TotalCost: 'totalCost'
};

export default class Costs {
  constructor() {
    // 服务费取值模式(默认向上取整)
    this.serviceCostsRoundingType = 10;
    // 服务费税率配置
    this.serviceCostsTaxType = ServiceCostsTaxTypes.TaxInclusive;
    // 服务费比率
    this.serviceCostsRate = 0;

    // 运费超出配置（默认允许超出）
    this.costsExcessType = CostsExcessTypes.AllowExcess;

    // 输入表单处理
    this.form = {
      name: '',
      value: null
    };

    this.maxCosts = null;
  }

  setRules(rules) {
    const { serviceCostsRoundingType, serviceCostsTaxType, costsExcessType, serviceCostsRate } = rules;
    this.serviceCostsRate = new BigNumber(serviceCostsRate || 0).div(10000).toNumber();
    this.serviceCostsRoundingType = ServiceCostsRoundingTypes[serviceCostsRoundingType] ?? null;
    this.serviceCostsTaxType = serviceCostsTaxType ?? this.serviceCostsTaxType;
    this.costsExcessType = costsExcessType ?? CostsExcessTypes.AllowExcess;
    return this;
  }

  // 设置最大值，根据规则限制运费
  setMaxCosts(maxCosts) {
    if (this.costsExcessType === CostsExcessTypes.NotAllowExcess) {
      this.maxCosts = maxCosts ?? null;
    } else {
      this.maxCosts = null;
    }

    return this;
  }

  setValues({ name, value }) {
    this.form.name = name;
    this.form.value = value;
    return this;
  }

  build() {
    let resultMap = { totalCosts: null, costs: null, serviceCosts: null };
    // 输入运费，计算服务费和总运费
    const { name, value } = this.form;
    if (!Utils.isNumber(value)) return resultMap;

    const isCostsInput =
      name === ActiveNames.CarrierTransportCost ||
      name === ActiveNames.ShipperTransportCost ||
      name === ActiveNames.Cost;

    if (isCostsInput) {
      resultMap.costs = Utils.isNumber(this.maxCosts) ? Math.min(Number(this.maxCosts), Number(value)) : Number(value);
      resultMap.serviceCosts = this.getServiceCosts(resultMap.costs);
      resultMap.totalCosts = new BigNumber(resultMap.costs)
        .plus(resultMap.serviceCosts)
        .integerValue(BigNumber.ROUND_FLOOR)
        .toNumber();
      return resultMap;
    }

    if (name === ActiveNames.TotalCost) {
      resultMap.totalCosts = Number(value);
      resultMap.costs = Utils.isNumber(this.maxCosts)
        ? Math.min(Number(this.maxCosts), this.getCosts(resultMap.totalCosts))
        : this.getCosts(resultMap.totalCosts);
      resultMap.serviceCosts = new BigNumber(resultMap.totalCosts)
        .integerValue(BigNumber.ROUND_FLOOR)
        .minus(resultMap.costs)
        .toNumber();
      return resultMap;
    }
    return resultMap;
  }

  getCosts(totalCosts) {
    // 托运方运费 = 总运费 /（1+服务费率）
    if (this.serviceCostsTaxType === ServiceCostsTaxTypes.TaxExclusive) {
      const rate = new BigNumber(this.serviceCostsRate).plus(1).toNumber();
      return new BigNumber(totalCosts)
        .div(rate)
        .integerValue(BigNumber.ROUND_FLOOR)
        .toNumber();
    }

    // 托运方运费 = 总运费 *（1—服务费率）
    const rate = new BigNumber(1).minus(this.serviceCostsRate).toNumber();
    return new BigNumber(totalCosts)
      .times(rate)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toNumber();
  }

  getServiceCosts(costs) {
    //   服务费 = 托运方运费 * 服务费率
    if (this.serviceCostsTaxType === ServiceCostsTaxTypes.TaxExclusive) {
      return new BigNumber(costs)
        .times(this.serviceCostsRate)
        .integerValue(this.serviceCostsRoundingType)
        .toNumber();
    }

    // 服务费 =托运方运费 * 服务费率 /（1—服务费率）
    const rate = new BigNumber(1).minus(this.serviceCostsRate).toNumber();
    return new BigNumber(costs)
      .times(this.serviceCostsRate)
      .div(rate)
      .integerValue(this.serviceCostsRoundingType)
      .toNumber();
  }

  // 总运费 = 托运方运费+服务费
  getTotalCosts(costs) {
    const serviceCosts = this.getServiceCosts(costs);
    return new BigNumber(serviceCosts)
      .plus(costs)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toNumber();
  }
}
