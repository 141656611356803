import Base from './Base';
import Utils from '../Utils';
import { AllowLossNumberUnitTypes, LossRuleTypes, PriceUnitTypes } from '../constants';
import UnitConverter from '../UnitConverter';
import BigNumber from 'bignumber.js';
/**
 * 计价规则第二部分
 * 亏损相关逻辑
 * 【亏损规则，允许亏损数量，亏损扣款单价】
 */
export default class LossRule extends Base {
  _getLabel() {
    const { typeLabel } = this.values;
    // 亏损数量label
    const lossNumberLabel = this._getAllowLossNumberLabel();
    // 亏损扣款单价label
    const lossPriceLabel = this._getAllowLossPriceLabel();
    return {
      lossNumberLabel,
      lossPriceLabel,
      typeLabel
    };
  }

  _getAllowLossNumber() {
    const { allowLossNumber, allowLossNumberUnit, priceUnitType } = this.rules;
    const { loadingNumber } = this.values;
    const { weightUnit } = this.options;
    // 千分比
    if (allowLossNumberUnit === AllowLossNumberUnitTypes.Permillage) {
      return new BigNumber(allowLossNumber)
        .times(loadingNumber)
        .div(100000)
        .toNumber();
    }
    // 吨 千克 方 件
    if (allowLossNumberUnit === AllowLossNumberUnitTypes.Unit) {
      if (priceUnitType === PriceUnitTypes.Weight) {
        return UnitConverter.gram2weight(allowLossNumber, weightUnit);
      }
      if (priceUnitType === PriceUnitTypes.Volume) {
        return UnitConverter.dm2volume(allowLossNumber);
      }
      if (priceUnitType === PriceUnitTypes.Number) {
        return UnitConverter.val2number(allowLossNumber);
      }
    }
    return null;
  }

  // 亏损数量label
  _getAllowLossNumberLabel() {
    const { allowLossNumberUnit, allowLossNumber } = this.rules;
    const { typeUnitLabel, typeLabel } = this.values;
    // console.log(this.values)
    if (Utils.isInvalidNumber(allowLossNumber)) {
      return '允许亏损';
    }
    if (allowLossNumberUnit === AllowLossNumberUnitTypes.Permillage) {
      const finalNumber = new BigNumber(allowLossNumber).div(100).toFixed(2);
      return `装货${typeLabel} * ${finalNumber}‰`;
    }
    if (allowLossNumberUnit === AllowLossNumberUnitTypes.Unit) {
      const finalNumber = parseFloat(Utils.keepDecimal(this._getAllowLossNumber(), 3));
      return `${finalNumber}${typeUnitLabel}`;
    }
    return '';
  }

  _getAllowLossPrice() {
    const { lossPrice, priceUnitType } = this.rules;
    const { weightUnit } = this.options;
    return UnitConverter.cent2priceUnit(lossPrice, priceUnitType, weightUnit);
  }

  // 亏损单价label
  _getAllowLossPriceLabel() {
    const { priceUnitLabel } = this.values;
    const lossPrice = this._getAllowLossPrice();
    if (Utils.isInvalidNumber(lossPrice)) {
      return '扣款单价';
    }
    const finalLossPrice = Utils.keepDecimal(lossPrice, 2);
    return `${finalLossPrice}${priceUnitLabel}`;
  }

  getFixedLabel() {
    return `${this.labels}`;
  }

  // 装货没有亏损规则
  getLoadingLabel() {
    return `${this.labels}`;
  }

  getUnloadingLabel() {
    const { lossRule } = this.rules;
    if (lossRule === LossRuleTypes.Deduct) {
      const { lossNumberLabel, lossPriceLabel, typeLabel } = this._getLabel();
      return `${this.labels} - (装货${typeLabel} - 卸货${typeLabel} - ${lossNumberLabel}) * ${lossPriceLabel}`;
    }
    return `${this.labels}`;
  }

  getMinimumLabel() {
    return this.getUnloadingLabel();
  }

  // 装货没有该规则
  getLoadingCost() {
    return this.costs;
  }

  // (装货重量 - 卸货重量 - 23.111吨）* 23.00元/吨
  getUnloadingCost() {
    if (!Utils.isNumber(this.costs)) {
      return this.costs;
    }
    const { lossRule } = this.rules;
    if (lossRule === LossRuleTypes.Deduct) {
      const { loadingNumber, unloadingNumber } = this.values;
      const lossPrice = this._getAllowLossPrice();
      if (
        Utils.isInvalidNumber(loadingNumber) ||
        Utils.isInvalidNumber(unloadingNumber) ||
        Utils.isInvalidNumber(lossPrice)
      ) {
        return this.costs;
      }
      // 装货重量 - 卸货重量 - 23.111吨 <=0
      const lossNumber = this._getAllowLossNumber() || 0;
      const diff = new BigNumber(loadingNumber)
        .minus(unloadingNumber)
        .minus(lossNumber)
        .toNumber();
      if (diff <= 0) {
        return this.costs;
      }
      const lossMoney = new BigNumber(diff).times(lossPrice).toNumber();
      return new BigNumber(this.costs).minus(lossMoney).toNumber();
    }
    return this.costs;
  }

  // min【装货重量，卸货重量】* 运输单价
  getMinimumCost() {
    return this.getUnloadingCost();
  }

  getFixedCost() {
    return this.costs;
  }
}
