import Base from './Base';
import Utils from '../Utils';
import BigNumber from 'bignumber.js';
import UnitConverter from '../UnitConverter';
import { PricePlanTypes, TheOtherFineUnitTypes } from '../constants';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });
/**
 * 其他罚扣
 * otherFine 「百分比，直接传入的数字」
 * otherFineUnit
 * 默认传入的是【分】
 */
export default class OtherFineRule extends Base {
  constructor() {
    super();
    this.instance = null;

    this.fineYuan = new FineYuan();
    this.finePercent = new FinePercent();
  }

  _getInstance() {
    const { otherFineUnit } = this.rules;
    if (otherFineUnit === TheOtherFineUnitTypes.Yuan) {
      this.instance = this.fineYuan;
    } else if (otherFineUnit === TheOtherFineUnitTypes.Percent) {
      this.instance = this.finePercent;
    }
    this.instance
      ?.setRules(this.rules)
      ?.setLabels(this.labels)
      ?.setCosts(this.costs);
    return this.instance;
  }

  _getLabels() {
    const instance = this._getInstance();
    if (!instance) return this.labels;
    return instance.getLabels();
  }

  _getCosts() {
    const instance = this._getInstance();
    if (!instance || !Utils.isNumber(this.costs)) return this.costs;
    return instance.getCosts();
  }

  getFixedLabel() {
    return this._getLabels();
  }

  getFixedCost() {
    return this._getCosts();
  }
  //
  getLoadingLabel() {
    return this._getLabels();
  }

  getUnloadingLabel() {
    return this._getLabels();
  }

  getMinimumLabel() {
    return this._getLabels();
  }

  // 装货重量 * 运输单价
  getLoadingCost() {
    return this._getCosts();
  }

  // 卸货重量 * 运输单价
  getUnloadingCost() {
    return this._getCosts();
  }

  // min【装货重量，卸货重量】* 运输单价
  getMinimumCost() {
    return this._getCosts();
  }
}

class FineYuan {
  constructor() {
    this.rules = null;
    this.labels = '';
    this.costs = null;
  }

  setRules(rules) {
    this.rules = rules;
    return this;
  }

  setLabels(labels) {
    this.labels = labels;
    return this;
  }

  setCosts(costs) {
    this.costs = costs;
    return this;
  }

  getLabels() {
    const { otherFine } = this.rules;
    if (Utils.isInvalidNumber(otherFine)) return this.labels;
    const finePrice = Utils.keepDecimal(UnitConverter.cent2yuan(otherFine), 2);
    return `${this.labels} - ${finePrice}元`;
  }

  getCosts() {
    const { otherFine } = this.rules;
    if (Utils.isInvalidNumber(otherFine)) return this.costs;
    return new BigNumber(this.costs).minus(UnitConverter.cent2yuan(otherFine)).toNumber();
  }
}

class FinePercent {
  constructor() {
    this.rules = null;
    this.labels = '';
    this.costs = null;
  }

  setRules(rules) {
    this.rules = rules;
    return this;
  }

  setLabels(labels) {
    this.labels = labels;
    return this;
  }

  setCosts(costs) {
    this.costs = costs;
    return this;
  }

  getLabels() {
    const { otherFine, pricePlanType } = this.rules;
    if (Utils.isInvalidNumber(otherFine)) return this.labels;
    const percent = Utils.keepDecimal(otherFine, 0);
    if (pricePlanType === PricePlanTypes.FixedPrice) {
      return `${this.labels} * (1 - ${percent}%)`;
    }
    return `(${this.labels}) * (1 - ${percent}%)`;
  }

  getCosts() {
    const { otherFine } = this.rules;
    if (Utils.isInvalidNumber(otherFine)) return this.costs;
    const costs = new BigNumber(this.costs).times(otherFine).div(100);
    return new BigNumber(this.costs).minus(costs).toNumber();
  }
}
